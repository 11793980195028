#wrapper {
    overflow-x: hidden;
  }
  
  #sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    transition: margin 0.25s ease-out;
  }
  
  #sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.4rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
  #sidebar-wrapper .list-group {
    width: 15rem;
  }
  
  #page-content-wrapper {
    min-width: 100vw;
  }
  
  #wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
  }

  .sidebar_icons {
      color: var(--light-color);
  }


  .list-group-item-action:focus,
  .list-group-item-action:hover,
  .list-group-item-action:hover .sidebar_icons,
  .list-group-item-action:focus .sidebar_icons {
    color: var(--dark-color);
    background-color: var(--light-color);
  }

  .list-group-item.active,
  .list-group-item.active .sidebar_icons {
    background-color: var(--light-color);
    border-color: var(--light-color);
    color: var(--dark-color);
  }

  /* Responsive */
  
  @media (min-width: 768px) {
    #sidebar-wrapper {
      margin-left: 0;
    }
  
    #page-content-wrapper {
      min-width: 0;
      width: 100%;
    }
  
    #wrapper.toggled #sidebar-wrapper {
      margin-left: -15rem;
    }
  }