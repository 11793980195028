
.formBox {
    width: 80%;
    margin: auto;
}

.text_bold {
    font-weight: bold;
}

@media (max-width: 600px) {
    .formBox {
        width: 95%;
    }
}