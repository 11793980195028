
/* Import CSS files */
@import './dist/css/header.css';
@import './dist/css/loginPage.css';
@import './dist/css/sidebarmenu.css';
@import './dist/css/reportsforms.css';
@import './dist/css/tables.css';
@import './dist/css/sigCanvas.css';

:root {
    --primary-color: #3f0000;
    --secondary-color: #ccc;
    --light-color: #fff;
    --dark-color: #000;
}

/* Basic Settings */
.btn:focus {
    box-shadow: none;
}

.hideThis {
    display: none;
}

/* Color Classes */
.bg_primary {
    background-color: var(--primary-color);
    color: var(--light-color);
}

.bg_secondary {
    background-color: var(--secondary-color);
}

.text_light {
    color: var(--light-color);
}

.text_dark {
    color: var(--dark-color);
}

.text_font {
    font-size: 1.2rem;
    font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
}

@media (max-width: 420px){
    .formTitle{
        font-size: 1.1rem;
    }
    .formLogo {
        width: 10rem;
    }
}