.sigCanvas {
    width: 100%;
    height: 100%;
}

#popup-root .popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 90%;
    height: 50%;
    padding: 5px;
  }

.sigImage {
    width: 100%;
    max-width: 15rem;
    max-height: 10rem;
    height: 100%;
}

@media (max-width: 700px){
    .sigButton {
        font-size: 1rem;
    }
}